<template>
    <v-navigation-drawer
      id="app-drawer"
      v-model="inputValue"
      app
      floating
      mobile-breakpoint="990"
      width="220"
      mini-variant-width="68"
      color="rgb(33,33,33)"
      dark
      :mini-variant="miniDrawer"
      :permanent="!$vuetify.breakpoint.xsOnly"
      class="elevation-0"
    >
        <v-list-item class="px-1 py-2" @click="miniDrawer=!miniDrawer">
          <v-list-item-avatar tile class="mx-2">
            <v-img src="/favicon.png"/>
          </v-list-item-avatar>
          <v-list-item-title class="font-weight-bold headline">
            MANAGRX
          </v-list-item-title>
        </v-list-item>
        <v-layout tag="v-list">
        <v-list shaped :nav="miniDrawer">
          <v-list-item
            v-for="(link, i) in links"
            :key="i"
            :to="link.to"
            class="mt-1 ml-0 mr-0 mb-0 list-item-custom"
            :active-class="colorTabs"
            :class="!miniDrawer&&'pr-10 pl-6'"
            style="max-height: 44px;"
          >
            <v-tooltip right color="#121212" v-if="miniDrawer">
              <template v-slot:activator="{on, attrs}">
                <v-list-item-action class="ma-3" v-on="on" v-bind="attrs">
                  <v-icon>{{ link.icon }}</v-icon>
                </v-list-item-action>
              </template>
              <span style="color: white">{{link.text}}</span>
            </v-tooltip>
            <v-list-item-icon class="mr-4" style="margin-top: auto; margin-bottom: auto">
              <v-icon v-text="link.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="link.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-layout>
    </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapMutations, mapState } from "vuex";

export default {
  data: () => ({
    links: [],
    responsive: false,
    expandOnHover: true,
    rounded: true,
    miniDrawer: true
  }),
  computed: {
    ...mapState({
        nombreEmpresa :  "nombreEmpresa",
        image : "image",
        colorTabs : "colorTabs",
        color: "color"
      }),
    //...mapState(["image", "color","colorTabs", "nombreEmpresa"]),
    inputValue: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.setDrawer(val);
      }
    },
    items() {
      return this.$t("Layout.View.items");
    },
   
  },
  beforeMount() {
    //this.links = this.links.concat(JSON.parse(localStorage.getItem('routes')));
    const nombreEmpresa = this.nombreEmpresa;
    this.links = this.links.concat(this.$store.state.routes);
    if (nombreEmpresa == "Geometrika") {  //para que solo en la instancia de GK se vea el cotizador (ícono)
        this.links.pop(); 
      }
    // this.links.push(
    //   {
    //     icon: "mdi-certificate",
    //     idSeccion: 15,
    //     position: 5,
    //     text: "Calidad",
    //     to: "/dashboard/calidad",
    //     vistas: []
    //   },
    // );

    this.links = this.links.map(link => ({
      
  ...link,
  text: link.text.trim().localeCompare("Configuración Inicial", undefined, { sensitivity: 'base' }) === 0
    ? "Configuración"
    : link.text
  }));

  

    /*this.links = this.links.map( link => {
    //   if (link.text === 'Dashboard'){
    //     link.to = '/dashboard/reportes';
    //   }
    //   if (link.text == 'Reportes') link.position = 1;
    //   if (link.text == "RH") link.position = 2;
    //   if (link.text == "Inventario") {
    //     link.icon = 'mdi-warehouse';
    //     link.text = 'Almacén';
    //     link.to = '/dashboard/almacen';
    //     link.position = 3;
    //   }
    //   if (link.text == "Producción") link.position = 4;
    //   if (link.text == "Mantenimiento") link.position = 6;
    //   if (link.text == "Compras") link.position = 7;
    //   if (link.text == "Ventas") link.position = 8;
      if (link.text.trim().toLowerCase() == "Configuración Inicial".trim().toLowerCase()) {
        link.text = 'Configuración';
      }
    //   if (link.text == "Seguridad") link.position = 10;
    //   if (link.text == "Recursos") link.position = 12;
    //   if (link.text == "OTS") link.position = 13;
    //   if (link.text == "Perfil") link.position = 14;
      // if (link.text.trim().toLowerCase().includes("Perfil".trim().toLowerCase())) {
      //     link.text = 'Perfil';
      // }

      return link; 
    });*/

    // .filter( 
    //   link => 
    //     link.text != "Dashboard" && 
    //     link.text != "OTS" && 
    //     link.text != "Perfil" &&
    //     link.text != "Comando y Control" &&
    //     link.text != "Recursos"
    //   )

    // this.links.sort((a,b) => parseInt(a.position)-parseInt(b.position));
    // console.log("Links: ",JSON.stringify(this.links,null,3));
  },
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
  methods: {
    ...mapMutations("app", ["setDrawer", "toggleDrawer"]),
    onResponsiveInverted() {
      if (window.innerWidth < 600) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    }
  }
};
</script>

<style lang="scss">
#app-drawer {
  .general {
    background-color: #ffa21a !important;
    border-color: #ffa21a !important;
  }
  .v-list__item {
    border-radius: 10px;
    //height: 35px;
    &--buy {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  .v-image__image--contain {
    top: 9px;
    height: 60%;
  }
  .managrx-headline:hover
  {
    cursor: pointer;
  }
}
</style>

<style scoped>
.v-navigation-drawer .v-list {
  background: rgba(0, 0, 0, 0) !important;
}

.theme--dark.v-list-item--active:before, .theme--dark.v-list-item--active:hover:before, .theme--dark.v-list-item:focus:before {
  opacity: 0;
}
</style>
